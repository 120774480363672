import React from 'react';

import Navbar from '../Components/Navbar';
import Main from './Main';

import countries from "countries-list";
import { AnimateOnChange } from 'react-animation';
import InfoPL from '../Components/InfoPL';

export default class PL extends React.Component {
    constructor(props) {
        super(props);
        this.state = { 
            teams: [
                
            ],
            positions: [
                "Goalkeeper",
                "Defender",
                "Midfielder",
                "Forward",
            ],
            heights: [
                    "5'0\"",
                    "5'1\"",
                    "5'2\"",
                    "5'3\"",
                    "5'4\"",
                    "5'5\"",
                    "5'6\"",
                    "5'7\"",
                    "5'8\"",
                    "5'9\"",
                    "5'10\"",
                    "5'11\"",
                    "6'0\"",
                    "6'1\"",
                    "6'2\"",
                    "6'3\"",
                    "6'4\"",
                    "6'5\"",
                    "6'6\"",
                    "6'7\"",
                    "6'8\"",
                    "6'9\"",
                    "6'10\"",
                    "6'11\"",
                    "7'0\"",
                    "7'1\"",
                    "7'2\"",
                    "7'3\"",
                    "7'4\"",
                    "7'5\"",
            ],
            teamImages: [],
            continent: "",
            similar: [[],[],[],[],[]],
            answer: [`Seattle Seawolves`,`Prop`,`6'0"`,`Andorra`,`20`],
            answerName: `John Smith`,
            answerImage: `https://upload.wikimedia.org/wikipedia/commons/0/0e/Stickman.png`,
            dataLoaded: false,
            info: false,
            teamsNorth: [],
            teamsSouth: [],
        };
        this.anchorTop = this.anchorTop.bind(this);
    }

    componentDidMount() {
        
        // attempting fetch

        // getting the current player
        fetch('/app/getPlayer/2')
            .then(response => response.json())
            .then(data => {
                this.setState({ answer: [data.team, data.position, data.height, data.nationality, data.age] })
                this.setState({ answerImage: data.image })
                this.setState({ answerName: data.name })
                this.setState({ dataLoaded: true });
            });

        // initializing teams for each conference
        let south = ["Luton Town", "Bournemouth", "Brighton & Hove Albion", "Arsenal", "Tottenham Hotspur", "Brentford", "Fulham", "Chelsea", "Crystal Palace", "West Ham United"];
        let north = ["Aston Villa", "Wolverhampton Wanderers", "Nottingham Forest", "Sheffield United", "Manchester United", "Manchester City", "Liverpool", "Everton", "Burnley", "Newcastle United"];
        let teamRelated = [];

        this.setState({ teamsSouth: south, teamsNorth: north });

        // getting team names
        fetch('/app/getTeams/2')
            .then(response => response.json())
            .then(data => {
                this.setState({ teams: data.teams, teamImages: data.teamImages }, ()=>{
                    // setting the similar teams
                    for (let i=0; i < this.state.teams.length; i++) {
                        let teamName = this.state.teams[i];
                        let otherTeams = [];

                        if (south.indexOf(this.state.teams[i]) !== -1) {
                            // team is in the south
                            for (let x=0; x < south.length; x++) {
                                if (south[x] !== teamName) {
                                    otherTeams.push(south[x]);
                                }
                            }
                            teamRelated.push([ teamName, otherTeams ]);
                        }
                        if (north.indexOf(this.state.teams[i]) !== -1) {
                            // team is in the north
                            for (let x=0; x < north.length; x++) {
                                if (north[x] !== teamName) {
                                    otherTeams.push(north[x]);
                                }
                            }
                            teamRelated.push([ teamName, otherTeams ]);
                        }
                    }
                    //console.log(teamRelated);
                    let similar = this.state.similar;
                    similar[0] = teamRelated;
                    this.setState({ similar: similar });
                    })
            });

        // setting similar positions
        let similarPositionsNone = this.state.similar;
        let similarPositions = [];

        for (let i=0; i < this.state.positions.length; i++) {
            if (this.state.positions[i] === "Goalkeeper") {
                similarPositions.push([this.state.positions[i], ["Defender"]]);
            }
            if (this.state.positions[i] === "Defender") {
                similarPositions.push([this.state.positions[i], ["Goalkeeper"]]);
            }
            if (this.state.positions[i] === "Midfielder") {
                similarPositions.push([this.state.positions[i], ["Forward"]]);
            }
            if (this.state.positions[i] === "Forward") {
                similarPositions.push([this.state.positions[i], ["Midfielder"]]);
            }
        }

        similarPositionsNone[1] = similarPositions;
        this.setState({similar: similarPositionsNone});

        // similar heights
        let similarHeightsNone = this.state.similar;
        let similarHeights = [];

        for (let i=0; i < this.state.heights.length; i++) {
            if (i === 0) {
                similarHeights.push([this.state.heights[i], [this.state.heights[i+1],this.state.heights[i+2]]])
            } else {
                if (i < this.state.heights.length - 3) { 
                    similarHeights.push([this.state.heights[i], [this.state.heights[i-2],this.state.heights[i-1],this.state.heights[i+1],this.state.heights[i+2]]])
                } else {
                    similarHeights.push([this.state.heights[i], [this.state.heights[i-2],this.state.heights[i-1]]])
                }
            }
        }
        similarHeightsNone[2] = similarHeights;
        this.setState({ similar: similarHeightsNone });


        // get countries
        let similarCountries = this.state.similar;
        let countryContinents = [["AF",[]], ["AN",[]], ["AS",[]], ["EU",[]], ["NA",[]], ["OC",[]], ["SA",[]]];
        const countryCodes = Object.keys(countries.countries);
        for (let i=0; i < countryCodes.length; i++) {
            if (countries.countries[countryCodes[i]].continent === "AF") {
                countryContinents[0][1].push(countries.countries[countryCodes[i]].name);
            }
            if (countries.countries[countryCodes[i]].continent === "AN") {
                countryContinents[1][1].push(countries.countries[countryCodes[i]].name);
            }
            if (countries.countries[countryCodes[i]].continent === "AS") {
                countryContinents[2][1].push(countries.countries[countryCodes[i]].name);
            }
            if (countries.countries[countryCodes[i]].continent === "EU") {
                countryContinents[3][1].push(countries.countries[countryCodes[i]].name);
            }
            if (countries.countries[countryCodes[i]].continent === "NA") {
                countryContinents[4][1].push(countries.countries[countryCodes[i]].name);
            }
            if (countries.countries[countryCodes[i]].continent === "OC") {
                countryContinents[5][1].push(countries.countries[countryCodes[i]].name);
            }
            if (countries.countries[countryCodes[i]].continent === "SA") {
                countryContinents[6][1].push(countries.countries[countryCodes[i]].name);
            }
        }
        // adding other countries
        countryContinents[3][1].push("England");
        countryContinents[3][1].push("Scotland");
        countryContinents[3][1].push("Wales");
        countryContinents[3][1].push("Northern Ireland");
        countryContinents[4][1].push("USA");

        similarCountries[3] = countryContinents;
        this.setState({ similar: similarCountries });
    }

    anchorTop() {
        window.scrollTo(0, 0);
        this.setState({ info: true });
    }

    render() {

        if (this.state.dataLoaded === true) {
            return(
                <div>
                { this.state.info ?
                    <div>
                        <Main infoLink={<InfoPL similar={this.state.similar} teamsNorth={this.state.teamsNorth} teamsSouth={this.state.teamsSouth} />} gameName={"Premier League"} gameLink={""} teams={this.state.teams} teamImages={this.state.teamImages} positions={this.state.positions} heights={this.state.heights} answer={this.state.answer} answerImage={this.state.answerImage} answerName={this.state.answerName} similar={this.state.similar} />
                    </div>
                    :
                    <div>
                        <Navbar />
                        <InfoPL similar={this.state.similar} teamsNorth={this.state.teamsNorth} teamsSouth={this.state.teamsSouth} />
                        <input type="submit" value="Play!" className='btn btn-success' onClick={()=>this.anchorTop()} style={{ display:"flex", justifyContent: 'center', textAlign: "center", marginLeft: "auto", marginRight: "auto"}}/>
                        <br/>
                    </div>
                }
                </div>
            );
        } else {
            return <div />
        }
    }
}