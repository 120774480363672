import React from 'react';

import Navbar from '../Components/Navbar';

import imageAbout from '../../Static/aboutme.png';

import { HideUntilLoaded } from 'react-animation';

export default class About extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return(
            <div>
                <Navbar />
                <div style={{marginTop: "25px", color: "white", minWidth: "300px", maxWidth: "500px", marginLeft: "auto", marginRight: "auto", textAlign: "center", justifyContent: "center"}}>
                    <h1 style={{textAlign: "center"}}>About</h1>
                    <p>
                        <div style={{padding: "10px", }}>
                            Welcome to Player Hunch!<br/>
                            Player Hunch was created for superfans of sports to test their knowledge about their sport.
                            We are passionate about sports and wanted to create a way of testing superfans knowledge, and so Player Hunch was created!

                            <br/>
                            Our first game on the website is Guess the Player for Major League Rugby. 
                            You can find this by going onto the games tab on the navigation bar and clicking "Guess the Player - Major League Rugby".
                            In the future we plan to expand our platform to include Football (Soccer) and more rugby competitions.

                            <br/><br/>
                            <u>About me</u>
                            <p>
                                Hi! I'm Matt and I created Player Hunch.<br/>
                                I enjoy watching sports, and coding! I decided to combine these to create Player Hunch.
                                If you have any problems concerning the website don't hesitate to contact me on Twitter!<br/>
                                <a target="_blank" href="https://twitter.com/newtinn"><i class="bi bi-twitter" style={{ color: "##00acee" }}></i>&nbsp;Twitter - @newtinn</a>
                            </p>
                            <br/>
                            <br/>
                        </div>
                    </p>
                </div>
            </div>
        );
    }
}