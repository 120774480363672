import React from 'react';

import Navbar from '../Components/Navbar';
import Main from './Main';

import countries from "countries-list";
import { AnimateOnChange } from 'react-animation';
import InfoMLR from '../Components/InfoMLR';

export default class MLR extends React.Component {
    constructor(props) {
        super(props);
        this.state = { 
            teams: [
                "Chicago Hounds",
                "Dallas Jackals",
                "Houston SaberCats",
                "San Diego Legion",
                "Seattle Seawolves",
                "Utah Warriors",
                "New England Free Jacks",
                "NOLA Gold",
                "Old Glory DC",
                "Rugby New York",
                "Rugby ATL",
                "Toronto Arrows"
            ],
            positions: [
                "Prop",
                "Hooker",
                "Lock",
                "Flanker",
                "Number 8",
                "Scrum-half",
                "Fly-half",
                "Centre",
                "Wing",
                "Fullback"
            ],
            heights: [
                    "5'0\"",
                    "5'1\"",
                    "5'2\"",
                    "5'3\"",
                    "5'4\"",
                    "5'5\"",
                    "5'6\"",
                    "5'7\"",
                    "5'8\"",
                    "5'9\"",
                    "5'10\"",
                    "5'11\"",
                    "6'0\"",
                    "6'1\"",
                    "6'2\"",
                    "6'3\"",
                    "6'4\"",
                    "6'5\"",
                    "6'6\"",
                    "6'7\"",
                    "6'8\"",
                    "6'9\"",
                    "6'10\"",
                    "6'11\"",
                    "7'0\"",
                    "7'1\"",
                    "7'2\"",
                    "7'3\"",
                    "7'4\"",
                    "7'5\"",
            ],
            teamImages: [],
            continent: "",
            similar: [[],[],[],[],[]],
            answer: [`Seattle Seawolves`,`Prop`,`6'0"`,`Andorra`,`20`],
            answerName: `John Smith`,
            answerImage: `https://upload.wikimedia.org/wikipedia/commons/0/0e/Stickman.png`,
            dataLoaded: false,
            info: false,
        };
        this.anchorTop = this.anchorTop.bind(this);
    }

    componentDidMount() {
        
        // attempting fetch

        // getting the current player
        fetch('/app/getPlayer/1')
            .then(response => response.json())
            .then(data => {
                this.setState({ answer: [data.team, data.position, data.height, data.nationality, data.age] })
                this.setState({ answerImage: data.image })
                this.setState({ answerName: data.name })
                this.setState({ dataLoaded: true });
            });

        // initializing teams for each conference
        let west = ['Chicago Hounds', 'Dallas Jackals', 'Houston SaberCats', 'San Diego Legion', 'Seattle Seawolves', 'Utah Warriors'];
        let east = ['New England Free Jacks', 'New Orleans Gold', 'Old Glory DC', 'Rugby New York', 'Rugby ATL', 'Toronto Arrows'];
        let teamRelated = [];

        // getting team names
        fetch('/app/getTeams/1')
            .then(response => response.json())
            .then(data => {
                this.setState({ teams: data.teams, teamImages: data.teamImages }, ()=>{
                    // setting the similar teams
                    for (let i=0; i < this.state.teams.length; i++) {
                        let teamName = this.state.teams[i];
                        let otherTeams = [];

                        if (west.indexOf(this.state.teams[i]) !== -1) {
                            // team is in the west
                            for (let x=0; x < west.length; x++) {
                                if (west[x] !== teamName) {
                                    otherTeams.push(west[x]);
                                }
                            }
                            teamRelated.push([ teamName, otherTeams ]);
                        }
                        if (east.indexOf(this.state.teams[i]) !== -1) {
                            // team is in the east
                            for (let x=0; x < east.length; x++) {
                                if (east[x] !== teamName) {
                                    otherTeams.push(east[x]);
                                }
                            }
                            teamRelated.push([ teamName, otherTeams ]);
                        }
                    }
                    //console.log(teamRelated);
                    let similar = this.state.similar;
                    similar[0] = teamRelated;
                    this.setState({ similar: similar });
                    })
            });

        // setting similar positions
        let similarPositionsNone = this.state.similar;
        let similarPositions = [];

        for (let i=0; i < this.state.positions.length; i++) {
            if (this.state.positions[i] === "Prop") {
                similarPositions.push([this.state.positions[i], ["Hooker"]])
            }
            if (this.state.positions[i] === "Hooker") {
                similarPositions.push([this.state.positions[i], ["Prop"]])
            }
            if (this.state.positions[i] === "Lock") {
                similarPositions.push([this.state.positions[i], ["Flanker"]])
            }
            if (this.state.positions[i] === "Flanker") {
                similarPositions.push([this.state.positions[i], ["Lock","Number 8"]])
            }
            if (this.state.positions[i] === "Scrum-half") {
                similarPositions.push([this.state.positions[i], ["Fly-half"]])
            }
            if (this.state.positions[i] === "Fly-half") {
                similarPositions.push([this.state.positions[i], ["Scrum-half"]])
            }
            if (this.state.positions[i] === "Centre") {
                similarPositions.push([this.state.positions[i], ["Fly-half","Wing"]])
            }
            if (this.state.positions[i] === "Wing") {
                similarPositions.push([this.state.positions[i], ["Centre","Fullback"]])
            }
            if (this.state.positions[i] === "Fullback") {
                similarPositions.push([this.state.positions[i], ["Wing"]])
            }
        }

        similarPositionsNone[1] = similarPositions;
        this.setState({similar: similarPositionsNone});

        // similar heights
        let similarHeightsNone = this.state.similar;
        let similarHeights = [];

        for (let i=0; i < this.state.heights.length; i++) {
            if (i === 0) {
                similarHeights.push([this.state.heights[i], [this.state.heights[i+1],this.state.heights[i+2]]])
            } else {
                if (i < this.state.heights.length - 3) { 
                    similarHeights.push([this.state.heights[i], [this.state.heights[i-2],this.state.heights[i-1],this.state.heights[i+1],this.state.heights[i+2]]])
                } else {
                    similarHeights.push([this.state.heights[i], [this.state.heights[i-2],this.state.heights[i-1]]])
                }
            }
        }
        similarHeightsNone[2] = similarHeights;
        this.setState({ similar: similarHeightsNone });


        // get countries
        let similarCountries = this.state.similar;
        let countryContinents = [["AF",[]], ["AN",[]], ["AS",[]], ["EU",[]], ["NA",[]], ["OC",[]], ["SA",[]]];
        const countryCodes = Object.keys(countries.countries);
        for (let i=0; i < countryCodes.length; i++) {
            if (countries.countries[countryCodes[i]].continent === "AF") {
                countryContinents[0][1].push(countries.countries[countryCodes[i]].name);
            }
            if (countries.countries[countryCodes[i]].continent === "AN") {
                countryContinents[1][1].push(countries.countries[countryCodes[i]].name);
            }
            if (countries.countries[countryCodes[i]].continent === "AS") {
                countryContinents[2][1].push(countries.countries[countryCodes[i]].name);
            }
            if (countries.countries[countryCodes[i]].continent === "EU") {
                countryContinents[3][1].push(countries.countries[countryCodes[i]].name);
            }
            if (countries.countries[countryCodes[i]].continent === "NA") {
                countryContinents[4][1].push(countries.countries[countryCodes[i]].name);
            }
            if (countries.countries[countryCodes[i]].continent === "OC") {
                countryContinents[5][1].push(countries.countries[countryCodes[i]].name);
            }
            if (countries.countries[countryCodes[i]].continent === "SA") {
                countryContinents[6][1].push(countries.countries[countryCodes[i]].name);
            }
        }
        // adding other countries
        countryContinents[3][1].push("England");
        countryContinents[3][1].push("Scotland");
        countryContinents[3][1].push("Wales");
        countryContinents[3][1].push("Northern Ireland");
        countryContinents[4][1].push("USA");

        similarCountries[3] = countryContinents;
        this.setState({ similar: similarCountries });
    }

    anchorTop() {
        window.scrollTo(0, 0);
        this.setState({ info: true });
    }

    render() {

        if (this.state.dataLoaded === true) {
            return(
                <div>
                { this.state.info ?
                    <div>
                        <Main infoLink={<InfoMLR similar={this.state.similar}/>} gameName={"MLR"} gameLink={""} teams={this.state.teams} teamImages={this.state.teamImages} positions={this.state.positions} heights={this.state.heights} answer={this.state.answer} answerImage={this.state.answerImage} answerName={this.state.answerName} similar={this.state.similar} />
                    </div>
                    :
                    <div>
                        <Navbar />
                        <InfoMLR similar={this.state.similar} />
                        <input type="submit" value="Play!" className='btn btn-success' onClick={()=>this.anchorTop()} style={{ display:"flex", justifyContent: 'center', textAlign: "center", marginLeft: "auto", marginRight: "auto"}}/>
                        <br/>
                    </div>
                }
                </div>
            );
        } else {
            return <div />
        }
    }
}