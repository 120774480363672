import React from "react";

import Box from "../Components/Box";
import Navbar from "../Components/Navbar";

import countries from "countries-list";

import { HideUntilLoaded } from 'react-animation';
import 'animate.css';

export default class Main extends React.Component {
  constructor(props) {
    super(props);
    this.state = { 
      // guess name and format: [team (multi-select), position (multi-select), height (multi-select), nationality (text), age/dob (number)]
      guesses: [[``,``,``,``,``], [``,``,``,``,``], [``,``,``,``,``], [``,``,``,``,``], [``,``,``,``,``]], 
      guessesLeft: 0, 
      currentGuess: "", 
      currentGuessIndex: 0,
      pastGuesses: [false, false, false, false],
      allCorrect: false,
      answer: this.props.answer/*[`Seattle Seawolves`,`Prop`,`6'0"`,`Andorra`,`20`]*/,
      answerName: `Bob`,
      answerNameGuess: ``,
      answerNameCurrent: ``,
      answerLoaded: false,
      teamIndex: [-1,-1,-1,-1,-1],
      continent: ``,

      inputType: 0,
      // set value={this.state.currentGuess} in component did mount
      inputs: [
      "Select Player Team",
      "Select Player Position",
      "Select Player Height",
      "Select Player Nationality",
      "Select Player Age"
      ],
      error: "",
      errorClass: "",
      allCountries: [{'name': 'Andorra', 'continent': 'Europe'},
      {'name': 'United Arab Emirates', 'continent': 'Asia'},
      {'name': 'Afghanistan', 'continent': 'Asia'},
      {'name': 'Antigua and Barbuda', 'continent': 'North America'},
      {'name': 'Anguilla', 'continent': 'North America'},
      {'name': 'Albania', 'continent': 'Europe'},
      {'name': 'Armenia', 'continent': 'Asia'},
      {'name': 'Angola', 'continent': 'Africa'},
      {'name': 'Antarctica', 'continent': 'Antarctica'},
      {'name': 'Argentina', 'continent': 'South America'},
      {'name': 'American Samoa', 'continent': 'Oceania'},
      {'name': 'Austria', 'continent': 'Europe'},
      {'name': 'Australia', 'continent': 'Oceania'},
      {'name': 'Aruba', 'continent': 'North America'},
      {'name': 'Aland', 'continent': 'Europe'},
      {'name': 'Azerbaijan', 'continent': 'Asia'},
      {'name': 'Bosnia and Herzegovina', 'continent': 'Europe'},
      {'name': 'Barbados', 'continent': 'North America'},
      {'name': 'Bangladesh', 'continent': 'Asia'},
      {'name': 'Belgium', 'continent': 'Europe'},
      {'name': 'Burkina Faso', 'continent': 'Africa'},
      {'name': 'Bulgaria', 'continent': 'Europe'},
      {'name': 'Bahrain', 'continent': 'Asia'},
      {'name': 'Burundi', 'continent': 'Africa'},
      {'name': 'Benin', 'continent': 'Africa'},
      {'name': 'Saint Barthelemy', 'continent': 'North America'},
      {'name': 'Bermuda', 'continent': 'North America'},
      {'name': 'Brunei', 'continent': 'Asia'},
      {'name': 'Bolivia', 'continent': 'South America'},
      {'name': 'Bonaire', 'continent': 'North America'},
      {'name': 'Brazil', 'continent': 'South America'},
      {'name': 'Bahamas', 'continent': 'North America'},
      {'name': 'Bhutan', 'continent': 'Asia'},
      {'name': 'Bouvet Island', 'continent': 'Antarctica'},
      {'name': 'Botswana', 'continent': 'Africa'},
      {'name': 'Belarus', 'continent': 'Europe'},
      {'name': 'Belize', 'continent': 'North America'},
      {'name': 'Canada', 'continent': 'North America'},
      {'name': 'Cocos (Keeling) Islands', 'continent': 'Asia'},
      {'name': 'Democratic Republic of the Congo', 'continent': 'Africa'},
      {'name': 'Central African Republic', 'continent': 'Africa'},
      {'name': 'Republic of the Congo', 'continent': 'Africa'},
      {'name': 'Switzerland', 'continent': 'Europe'},
      {'name': 'Ivory Coast', 'continent': 'Africa'},
      {'name': 'Cook Islands', 'continent': 'Oceania'},
      {'name': 'Chile', 'continent': 'South America'},
      {'name': 'Cameroon', 'continent': 'Africa'},
      {'name': 'China', 'continent': 'Asia'},
      {'name': 'Colombia', 'continent': 'South America'},
      {'name': 'Costa Rica', 'continent': 'North America'},
      {'name': 'Cuba', 'continent': 'North America'},
      {'name': 'Cape Verde', 'continent': 'Africa'},
      {'name': 'Curacao', 'continent': 'North America'},
      {'name': 'Christmas Island', 'continent': 'Asia'},
      {'name': 'Cyprus', 'continent': 'Europe'},
      {'name': 'Czech Republic', 'continent': 'Europe'},
      {'name': 'Germany', 'continent': 'Europe'},
      {'name': 'Djibouti', 'continent': 'Africa'},
      {'name': 'Denmark', 'continent': 'Europe'},
      {'name': 'Dominica', 'continent': 'North America'},
      {'name': 'Dominican Republic', 'continent': 'North America'},
      {'name': 'Algeria', 'continent': 'Africa'},
      {'name': 'Ecuador', 'continent': 'South America'},
      {'name': 'Estonia', 'continent': 'Europe'},
      {'name': 'Egypt', 'continent': 'Africa'},
      {'name': 'Western Sahara', 'continent': 'Africa'},
      {'name': 'Eritrea', 'continent': 'Africa'},
      {'name': 'Spain', 'continent': 'Europe'},
      {'name': 'Ethiopia', 'continent': 'Africa'},
      {'name': 'Finland', 'continent': 'Europe'},
      {'name': 'Fiji', 'continent': 'Oceania'},
      {'name': 'Falkland Islands', 'continent': 'South America'},
      {'name': 'Micronesia', 'continent': 'Oceania'},
      {'name': 'Faroe Islands', 'continent': 'Europe'},
      {'name': 'France', 'continent': 'Europe'},
      {'name': 'Gabon', 'continent': 'Africa'},
      {'name': 'United Kingdom', 'continent': 'Europe'},
      {'name': 'Grenada', 'continent': 'North America'},
      {'name': 'Georgia', 'continent': 'Asia'},
      {'name': 'French Guiana', 'continent': 'South America'},
      {'name': 'Guernsey', 'continent': 'Europe'},
      {'name': 'Ghana', 'continent': 'Africa'},
      {'name': 'Gibraltar', 'continent': 'Europe'},
      {'name': 'Greenland', 'continent': 'North America'},
      {'name': 'Gambia', 'continent': 'Africa'},
      {'name': 'Guinea', 'continent': 'Africa'},
      {'name': 'Guadeloupe', 'continent': 'North America'},
      {'name': 'Equatorial Guinea', 'continent': 'Africa'},
      {'name': 'Greece', 'continent': 'Europe'},
      {'name': 'South Georgia and the South Sandwich Islands', 'continent': 'Antarctica'},
      {'name': 'Guatemala', 'continent': 'North America'},
      {'name': 'Guam', 'continent': 'Oceania'},
      {'name': 'Guinea-Bissau', 'continent': 'Africa'},
      {'name': 'Guyana', 'continent': 'South America'},
      {'name': 'Hong Kong', 'continent': 'Asia'},
      {'name': 'Heard Island and McDonald Islands', 'continent': 'Antarctica'},
      {'name': 'Honduras', 'continent': 'North America'},
      {'name': 'Croatia', 'continent': 'Europe'},
      {'name': 'Haiti', 'continent': 'North America'},
      {'name': 'Hungary', 'continent': 'Europe'},
      {'name': 'Indonesia', 'continent': 'Asia'},
      {'name': 'Ireland', 'continent': 'Europe'},
      {'name': 'Israel', 'continent': 'Asia'},
      {'name': 'Isle of Man', 'continent': 'Europe'},
      {'name': 'India', 'continent': 'Asia'},
      {'name': 'British Indian Ocean Territory', 'continent': 'Asia'},
      {'name': 'Iraq', 'continent': 'Asia'},
      {'name': 'Iran', 'continent': 'Asia'},
      {'name': 'Iceland', 'continent': 'Europe'},
      {'name': 'Italy', 'continent': 'Europe'},
      {'name': 'Jersey', 'continent': 'Europe'},
      {'name': 'Jamaica', 'continent': 'North America'},
      {'name': 'Jordan', 'continent': 'Asia'},
      {'name': 'Japan', 'continent': 'Asia'},
      {'name': 'Kenya', 'continent': 'Africa'},
      {'name': 'Kyrgyzstan', 'continent': 'Asia'},
      {'name': 'Cambodia', 'continent': 'Asia'},
      {'name': 'Kiribati', 'continent': 'Oceania'},
      {'name': 'Comoros', 'continent': 'Africa'},
      {'name': 'Saint Kitts and Nevis', 'continent': 'North America'},
      {'name': 'North Korea', 'continent': 'Asia'},
      {'name': 'South Korea', 'continent': 'Asia'},
      {'name': 'Kuwait', 'continent': 'Asia'},
      {'name': 'Cayman Islands', 'continent': 'North America'},
      {'name': 'Kazakhstan', 'continent': 'Asia'},
      {'name': 'Laos', 'continent': 'Asia'},
      {'name': 'Lebanon', 'continent': 'Asia'},
      {'name': 'Saint Lucia', 'continent': 'North America'},
      {'name': 'Liechtenstein', 'continent': 'Europe'},
      {'name': 'Sri Lanka', 'continent': 'Asia'},
      {'name': 'Liberia', 'continent': 'Africa'},
      {'name': 'Lesotho', 'continent': 'Africa'},
      {'name': 'Lithuania', 'continent': 'Europe'},
      {'name': 'Luxembourg', 'continent': 'Europe'},
      {'name': 'Latvia', 'continent': 'Europe'},
      {'name': 'Libya', 'continent': 'Africa'},
      {'name': 'Morocco', 'continent': 'Africa'},
      {'name': 'Monaco', 'continent': 'Europe'},
      {'name': 'Moldova', 'continent': 'Europe'},
      {'name': 'Montenegro', 'continent': 'Europe'},
      {'name': 'Saint Martin', 'continent': 'North America'},
      {'name': 'Madagascar', 'continent': 'Africa'},
      {'name': 'Marshall Islands', 'continent': 'Oceania'},
      {'name': 'North Macedonia', 'continent': 'Europe'},
      {'name': 'Mali', 'continent': 'Africa'},
      {'name': 'Myanmar (Burma)', 'continent': 'Asia'},
      {'name': 'Mongolia', 'continent': 'Asia'},
      {'name': 'Macao', 'continent': 'Asia'},
      {'name': 'Northern Mariana Islands', 'continent': 'Oceania'},
      {'name': 'Martinique', 'continent': 'North America'},
      {'name': 'Mauritania', 'continent': 'Africa'},
      {'name': 'Montserrat', 'continent': 'North America'},
      {'name': 'Malta', 'continent': 'Europe'},
      {'name': 'Mauritius', 'continent': 'Africa'},
      {'name': 'Maldives', 'continent': 'Asia'},
      {'name': 'Malawi', 'continent': 'Africa'},
      {'name': 'Mexico', 'continent': 'North America'},
      {'name': 'Malaysia', 'continent': 'Asia'},
      {'name': 'Mozambique', 'continent': 'Africa'},
      {'name': 'Namibia', 'continent': 'Africa'},
      {'name': 'New Caledonia', 'continent': 'Oceania'},
      {'name': 'Niger', 'continent': 'Africa'},
      {'name': 'Norfolk Island', 'continent': 'Oceania'},
      {'name': 'Nigeria', 'continent': 'Africa'},
      {'name': 'Nicaragua', 'continent': 'North America'},
      {'name': 'Netherlands', 'continent': 'Europe'},
      {'name': 'Norway', 'continent': 'Europe'},
      {'name': 'Nepal', 'continent': 'Asia'},
      {'name': 'Nauru', 'continent': 'Oceania'},
      {'name': 'Niue', 'continent': 'Oceania'},
      {'name': 'New Zealand', 'continent': 'Oceania'},
      {'name': 'Oman', 'continent': 'Asia'},
      {'name': 'Panama', 'continent': 'North America'},
      {'name': 'Peru', 'continent': 'South America'},
      {'name': 'French Polynesia', 'continent': 'Oceania'},
      {'name': 'Papua New Guinea', 'continent': 'Oceania'},
      {'name': 'Philippines', 'continent': 'Asia'},
      {'name': 'Pakistan', 'continent': 'Asia'},
      {'name': 'Poland', 'continent': 'Europe'},
      {'name': 'Saint Pierre and Miquelon', 'continent': 'North America'},
      {'name': 'Pitcairn Islands', 'continent': 'Oceania'},
      {'name': 'Puerto Rico', 'continent': 'North America'},
      {'name': 'Palestine', 'continent': 'Asia'},
      {'name': 'Portugal', 'continent': 'Europe'},
      {'name': 'Palau', 'continent': 'Oceania'},
      {'name': 'Paraguay', 'continent': 'South America'},
      {'name': 'Qatar', 'continent': 'Asia'},
      {'name': 'Reunion', 'continent': 'Africa'},
      {'name': 'Romania', 'continent': 'Europe'},
      {'name': 'Serbia', 'continent': 'Europe'},
      {'name': 'Russia', 'continent': 'Asia'},
      {'name': 'Rwanda', 'continent': 'Africa'},
      {'name': 'Saudi Arabia', 'continent': 'Asia'},
      {'name': 'Solomon Islands', 'continent': 'Oceania'},
      {'name': 'Seychelles', 'continent': 'Africa'},
      {'name': 'Sudan', 'continent': 'Africa'},
      {'name': 'Sweden', 'continent': 'Europe'},
      {'name': 'Singapore', 'continent': 'Asia'},
      {'name': 'Saint Helena', 'continent': 'Africa'},
      {'name': 'Slovenia', 'continent': 'Europe'},
      {'name': 'Svalbard and Jan Mayen', 'continent': 'Europe'},
      {'name': 'Slovakia', 'continent': 'Europe'},
      {'name': 'Sierra Leone', 'continent': 'Africa'},
      {'name': 'San Marino', 'continent': 'Europe'},
      {'name': 'Senegal', 'continent': 'Africa'},
      {'name': 'Somalia', 'continent': 'Africa'},
      {'name': 'Suriname', 'continent': 'South America'},
      {'name': 'South Sudan', 'continent': 'Africa'},
      {'name': 'Sao Tome and Principe', 'continent': 'Africa'},
      {'name': 'El Salvador', 'continent': 'North America'},
      {'name': 'Sint Maarten', 'continent': 'North America'},
      {'name': 'Syria', 'continent': 'Asia'},
      {'name': 'Eswatini', 'continent': 'Africa'},
      {'name': 'Turks and Caicos Islands', 'continent': 'North America'},
      {'name': 'Chad', 'continent': 'Africa'},
      {'name': 'French Southern Territories', 'continent': 'Antarctica'},
      {'name': 'Togo', 'continent': 'Africa'},
      {'name': 'Thailand', 'continent': 'Asia'},
      {'name': 'Tajikistan', 'continent': 'Asia'},
      {'name': 'Tokelau', 'continent': 'Oceania'},
      {'name': 'East Timor', 'continent': 'Oceania'},
      {'name': 'Turkmenistan', 'continent': 'Asia'},
      {'name': 'Tunisia', 'continent': 'Africa'},
      {'name': 'Tonga', 'continent': 'Oceania'},
      {'name': 'Turkey', 'continent': 'Asia'},
      {'name': 'Trinidad and Tobago', 'continent': 'North America'},
      {'name': 'Tuvalu', 'continent': 'Oceania'},
      {'name': 'Taiwan', 'continent': 'Asia'},
      {'name': 'Tanzania', 'continent': 'Africa'},
      {'name': 'Ukraine', 'continent': 'Europe'},
      {'name': 'Uganda', 'continent': 'Africa'},
      {'name': 'U.S. Minor Outlying Islands', 'continent': 'Oceania'},
      {'name': 'United States', 'continent': 'North America'},
      {'name': 'Uruguay', 'continent': 'South America'},
      {'name': 'Uzbekistan', 'continent': 'Asia'},
      {'name': 'Vatican City', 'continent': 'Europe'},
      {'name': 'Saint Vincent and the Grenadines', 'continent': 'North America'},
      {'name': 'Venezuela', 'continent': 'South America'},
      {'name': 'British Virgin Islands', 'continent': 'North America'},
      {'name': 'U.S. Virgin Islands', 'continent': 'North America'},
      {'name': 'Vietnam', 'continent': 'Asia'},
      {'name': 'Vanuatu', 'continent': 'Oceania'},
      {'name': 'Wallis and Futuna', 'continent': 'Oceania'},
      {'name': 'Samoa', 'continent': 'Oceania'},
      {'name': 'Kosovo', 'continent': 'Europe'},
      {'name': 'Yemen', 'continent': 'Asia'},
      {'name': 'Mayotte', 'continent': 'Africa'},
      {'name': 'South Africa', 'continent': 'Africa'},
      {'name': 'Zambia', 'continent': 'Africa'},
      {'name': 'Zimbabwe', 'continent': 'Africa'}
      ],
      info: false
    };

    this.changeValue = this.changeValue.bind(this);
    this.changeValueName = this.changeValueName.bind(this);
    this.increase = this.increase.bind(this);
    this.decrease = this.decrease.bind(this);
    this.submitGuess = this.submitGuess.bind(this);
    this.processAnswerName = this.processAnswerName.bind(this);
    this.findCountry = this.findCountry.bind(this);
  }

  findCountry(name) {
    //console.log("called!");
    /*let countryCodes = Object.keys(countries.countries);
    for (let i=0; i < countryCodes.length; i++) {
      if (countries.countries[countryCodes[i]].name === name) {
        //console.log(countries.countries[countryCodes[i]]);
        //console.log(typeof(countries.countries[countryCodes[i]].continent));
        return countries.countries[countryCodes[i]].continent;
        //continentNew = countries.continents[countryCodes[i]].continent;
        //break;
      }
    }
    if (name === `USA`) {
      return `NA`;
    }
    if (name === `Scotland` || name === `England` || name === `Wales` || name === `Northern Ireland`) {
      return `EU`;
    }*/
    let continent = 'NA';

    // looping through list of countries and returning the continent
    for (let i=0; i < this.state.allCountries.length; i++) {
      if (this.state.allCountries[i].name.toLowerCase() === name.toLowerCase()) {
          continent = this.state.allCountries[i].continent;
          break;
      }
    }
    return continent;


    //console.log(continent);
    //this.setState({ continent: continentNew });
    //console.log(continentNew);
  }

  componentDidMount() {
    // loading all countries
    let countryNames = this.state.allCountries;

    // replacing with common names/adding common names
    countryNames[countryNames.indexOf("United States")] = "USA";
    countryNames.push({"name": "Scotland", "continent": "EU"});
    countryNames.push({"name": "Wales", "continent": "EU"});
    countryNames.push({"name": "England", "continent": "EU"});
    countryNames.push({"name": "Northern Ireland", "continent": "EU"});
    countryNames = countryNames.sort((a, b) => {
      // Convert names to lowercase for case-insensitive comparison
      const nameA = a.name.toLowerCase();
      const nameB = b.name.toLowerCase();
  
      // Compare the two names
      if (nameA < nameB) {
          return -1; // 'a' should come before 'b' in the sorted order
      }
      if (nameA > nameB) {
          return 1; // 'a' should come after 'b' in the sorted order
      }
      return 0; // names are equal
  });;

    this.setState({ allCountries: countryNames });

    this.setState({ answer: this.props.answer });
    this.setState({ answerName: this.props.answerName });
    let continentNow = this.findCountry(this.props.answer[3]);
    this.setState({ continent: continentNow });

    /*if (this.state.guessesLeft !== 5) {
      for (let i=0; i < 5-this.state.guessesLeft; i++) {
        this.submitGuess();
      }
    }*/
  }

  changeValue(value) {
    //console.log(value);

    this.setState({ currentGuess: value });
    let thisGuesses = this.state.guesses;
    thisGuesses[this.state.guessesLeft][this.state.currentGuessIndex] = value;
    this.setState({ guesses: thisGuesses });
    //this.checkInputType(index);
    for (let i=0; i < this.props.teams.length; i++) {
      if (this.state.guesses[this.state.guessesLeft][this.state.currentGuessIndex] === this.props.teams[i]) {
        let currentAllTeamIndex = this.state.teamIndex;
        currentAllTeamIndex[this.state.guessesLeft] = i;
        this.setState({ teamIndex: currentAllTeamIndex });
        break;
      }
    }

    this.setState({ error: "", errorClass: "" });
  }

  changeValueName(value) {
    this.setState({ answerNameCurrent: value });
  }

  processAnswerName() {
    let name = this.state.answerNameCurrent.toLowerCase();

    if (name.length === 0) {
      return; 
    }

    // trimming the name
    name = name.trim();
    name = name.replace(/ +(?= )/g,'');

    this.setState({ answerNameGuess: name });
    this.setState({ guessesLeft: this.state.guessesLeft + 1 });
    this.setState({ answerNameCurrent: "" });
  }

  increase() {
    let index = this.state.currentGuessIndex;
    if (index < 4) {
      index += 1;
    } else {
      index = 0;
    }
    // loading the current guess
    let current = this.state.guesses[this.state.guessesLeft][index];
    this.setState({ currentGuess: current });
    this.setState({ currentGuessIndex: index });
    this.checkInputType(index);
  }

  decrease() {
    let index = this.state.currentGuessIndex;
    if (index > 0) {
      index -= 1;
    } else {
      index = 4;
    }
    // loading the current guess
    let current = this.state.guesses[this.state.guessesLeft][index];
    this.setState({ currentGuess: current });
    this.setState({ currentGuessIndex: index });
    this.checkInputType(index);
  }

  checkInputType(index) {
    // guess name and format: [team (multi-select), position (multi-select), height (multi-select), nationality (text), age/dob (number)]

    if (index === 0) {
      this.setState({ currentGuess: this.state.guesses[this.state.guessesLeft][index] });
    }

  }

  submitGuess(source) {
    if (this.state.guessesLeft < 5) {
      let current = this.state.guessesLeft;

      if (source !== 'info') {
        // validations
        if (this.state.guesses[current][0] === `` || this.state.guesses[current][1] === `` || this.state.guesses[current][2] === `` || this.state.guesses[current][3] === `` || this.state.guesses[current][4] === ``) {
          this.setState({ error: "Invalid Answer: some fields have not been completed", errorClass: "animate__animated animate__shakeX" });
          return;
        }

        // checking if valid country
        let countryFound = false;

        for (let country=0; country < this.state.allCountries.length; country++) {
          if (this.state.allCountries[country].name === this.state.guesses[current][3]) {
            countryFound = true;
            break;
          }
        }

        if (countryFound === false) {
          this.setState({ error: "country not found", errorClass: "animate__animated animate__shakeX" });
          return;
        }

        // checking if valid age
        if (parseInt(this.state.guesses[current][4]) < 16) {
          this.setState({ error: "Invalid age - please set the player age larger than 16.", errorClass: "animate__animated animate__shakeX" });
          return;
        }

        this.setState({ error: ""});
      }

      let allCorrect = true;
      for (let i=0; i < this.state.guesses[current].length; i++) {
        if (this.state.guesses[current][i].toLowerCase() !== this.state.answer[i].toLowerCase()) {
          allCorrect = false;
          break;
        }
      }
      if (allCorrect === true) {
        this.setState({ allCorrect: true });
      }
      
      if (source !== 'info') {
        let past = this.state.pastGuesses;
        past[current] = true;
        this.setState({ pastGuesses: past });

        current += 1;
      }
      this.setState({guessesLeft: current });
      this.setState({ currentGuess: "", currentGuessIndex: 0 });

      window.scrollTo(0,0);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.info !== prevState.info) {
      console.log('yes');
      console.log(this.state.guessesLeft);

      for (let i=0; i < this.state.guessesLeft; i++) {
        this.submitGuess('info');
      }

      window.scrollTo(0, 0);
    }
  }

  render() {
    return (
      <div>
        <Navbar />
        <div
          style={{
            marginLeft: "auto",
            marginRight: "auto",
            textAlign: "center",
            marginTop: "20px",
            marginBottom: "100px",
            color: "white"
          }}
        >
          

          { this.state.info ?
            <div>
              {this.props.infoLink}
              <input type="submit" value="Play!" className='btn btn-success' onClick={()=>this.setState({ info: false })} style={{ display:"flex", justifyContent: 'center', textAlign: "center", marginLeft: "auto", marginRight: "auto"}}/>
            </div>
          :

          <div>
            <div>
              <h1>Guess the Player!</h1>
              <h5>({this.props.gameName} Edition)&nbsp;
              <a onClick={()=>this.setState({ info: true })} style={{cursor: "pointer"}} className="question">
                <i class="bi bi-question-circle-fill"></i>
              </a>
              </h5>
            </div>
          { ((6-this.state.guessesLeft) <= 0 || (this.state.answerNameGuess.toLowerCase() === this.state.answerName.toLowerCase()) || (this.state.answerNameGuess.toLowerCase().replaceAll(".","") === this.state.answerName.toLowerCase().replaceAll(".",""))) ?
                <div style={{marginTop: "25px", maxWidth: "600px", boxSizing: "content-box", display: "flex", marginLeft: "auto", marginRight: "auto", flexDirection: "column"}}>
                  <br/>
                  <div style={{display: "flex", justifyContent: "center", maxWidth: "250px", marginLeft: "auto", marginRight: "auto"}}>
                    <HideUntilLoaded imageToLoad={this.props.answerImage} Spinner={()=> 
                      <div style={{width: "100%", height: "200px", display: "flex", justifyContent: "center", alignContent: "center"}}>
                        <span className="loader" style={{textAlign: "center", marginLeft: "auto", marginRight: "auto", display: "flex", justifyContent: "center", width: "50px", height: "50px"}}></span>
                      </div>
                      }>
                      <img src={this.props.answerImage} className="img-fluid" style={{display: "flex", maxWidth: "250px", height: "auto", justifyContent: "center", marginLeft: "auto", marginRight: "auto"}} onLoad={()=> this.setState({ answerLoaded: true })} />
                    </HideUntilLoaded>
                  </div>
                  <br/><br/>
                  <div style={{textAlign: "center", marginLeft: "auto", marginRight: "auto", marginTop: "10px", wordWrap: "break-word", display: "block"}}>
                    {
                      this.state.answerNameGuess.toLowerCase() === this.state.answerName.toLowerCase() || (this.state.answerNameGuess.toLowerCase().replaceAll(".","") === this.state.answerName.toLowerCase().replaceAll(".","")) ?
                      <h5>Congratulations!<br/>You got the answer!<br/>The player was {this.state.answerName}.</h5>:
                      <h5>Sorry, you did not get the player.<br/>The player was {this.state.answerName}.</h5>
                    }
                    <li>Team: {this.state.answer[0]}</li>
                    <li>Position: {this.state.answer[1]}</li>
                    <li>Height: {this.state.answer[2]}</li>
                    <li>Nationality: {this.state.answer[3]}</li>
                    <li>Age: {this.state.answer[4]}</li>
                    <br/>
                    <a href="/home"><input type="submit" value="Home" class="btn btn-warning" style={{marginBottom: "10px"}} /></a><br/>
                  </div>
                </div> 
                :
            <div style={{display: "flex", justifyContent: "center", marginLeft: "auto", marginRight: "auto"}}>
              <div
                className="grid-container"
                style={{marginTop: "25px"}}
              >
              {this.state.guesses.map((guess, x, key) =>
                  guess.map((now, y, key2)=>(
                      <div>
                        <Box value={now} key={y*10} x={x} y={y} answer={this.state.answer} guess={this.state.guesses[x]} currentGuess={this.state.guessesLeft} past={this.state.pastGuesses} teams={this.props.teams} teamImages={this.props.teamImages} teamIndex={this.state.teamIndex[x]} similar={this.props.similar} continent={this.state.continent} />
                      </div>
                  ))
              )}
              </div>
            </div>
          }
          
          <br/>
          {
            (this.state.guessesLeft < 5 && this.state.allCorrect === false) ? 
            <div className={this.state.errorClass}>
                { this.state.error.length > 0 ?
                  <p style={{color: "#E63946"}}><b>{this.state.error}</b></p> : null
                }

                <h4>{this.state.inputs[this.state.currentGuessIndex]}</h4>
                <div>
                <input class="changeButton" type="submit" value="<" onClick={()=>this.decrease()}/>
                {
                  this.state.currentGuessIndex === 0 ?
                  <div className="inputBorder">
                    <select value={this.state.currentGuess} onChange={(e) => this.changeValue(e.target.value)} className="inputBox">
                      <option>Select Team</option>
                      {this.props.teams.map((team, i)=>
                      <option key={i} className="inputBox">{team}</option>
                      )}
                    </select>
                  </div> 
                  :
                  null
                }

                {
                  this.state.currentGuessIndex === 1 ?
                  <div className="inputBorder">
                    <select value={this.state.currentGuess} onChange={(e) => this.changeValue(e.target.value)} className="inputBox">
                      <option>Select Position</option>
                      {this.props.positions.map((position, i)=>
                      <option key={i} className="inputBox">{position}</option>
                      )}
                    </select> 
                  </div>
                  :
                  null
                }

                {
                  this.state.currentGuessIndex === 2 ?
                  <div className="inputBorder">
                    <select value={this.state.currentGuess} onChange={(e) => this.changeValue(e.target.value)} className="inputBox">
                      <option>Select Height</option>
                      {this.props.heights.map((height, i)=>
                      <option key={i} className="inputBox">{height}</option>
                      )}
                    </select> 
                  </div>
                  :
                  null
                }

                {
                  this.state.currentGuessIndex === 3 ?
                  <div className="inputBorder">
                    <select value={this.state.currentGuess} onChange={(e) => this.changeValue(e.target.value)} className="inputBox">
                      <option>Select Nationality</option>
                      {this.state.allCountries.map((country, i)=>
                      <option key={i} className="inputBox">{country.name}</option>
                      )}
                    </select>
                  </div>
                  :
                  null
                }

                {
                  this.state.currentGuessIndex === 4 ?
                  <div className="inputBorder">
                    <input value={this.state.currentGuess} type="number" min="16" onChange={(e) => this.changeValue(e.target.value)} className="inputBox" />
                  </div>
                  :
                  null
                }
                <input class="changeButton" type="submit" value=">" onClick={()=>this.increase()}/>
              </div>
              <br/>
              <div className="inputBorder">
                <input type="submit" value="Submit" onClick={()=>this.submitGuess('')} className="inputBox" /> 
              </div>
            </div>
            :
            <div>
              {
                ((6-this.state.guessesLeft) > 0 && ((this.state.answerNameGuess.toLowerCase() !== this.state.answerName.toLowerCase()) && (this.state.answerNameGuess.toLowerCase().replaceAll(".","") !== this.state.answerName.toLowerCase().replaceAll(".","")))) ?
                <div>
                  <p style={{lineHeight: "0.1px"}}>Using the hints above,</p>
                  <h3>Guess the player name</h3>
                  <h6>You have {6-this.state.guessesLeft} Guesses Remaining</h6>
                  <div className="inputBorder" style={{marginTop: "5px"}}>
                    <input value={this.state.answerNameCurrent} type="text" placeholder="Guess the player name" onChange={(e)=>this.changeValueName(e.target.value)} className="inputBox" /><br/>
                  </div>
                  <br/>
                  <br/>
                  <div className="inputBorder">
                    <input type="submit" value="Submit" onClick={()=>this.processAnswerName()} className="inputBox" />
                  </div>
                </div>
                :
                null
              }
            </div>
          }
          </div>     
          }
        </div>
      </div>
    );
  }
}
