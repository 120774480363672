import React from "react";

import { AnimateOnChange, HideUntilLoaded } from 'react-animation';
import 'animate.css';

export default class Box extends React.Component {
  constructor(props) {
    super(props);
    this.state = {image: false, imageLoaded: false, color: "#3A3A3C", colorBG: "#121213", myClass: "" };
  }

  componentDidMount(prevProps, prevState) {
    // If team, show image
    
    //console.log("Current Guess: " + this.props.x + ", Current Box: " + this.props.y);

    if (this.state.imageLoaded !== false) {
      this.setState({ imageLoaded: false });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.value !== this.props.value) {
      this.setState({ image: false }, ()=> (
        this.setState({ imageLoaded: false })
      ));
    }

    if (this.props.y === 0) {
      if (this.state.image === false && this.state.imageLoaded === false) {
        this.setState({ image: true });
      }
    }
    //console.log(prevProps.value + " ## " + this.props.value);

    if (this.props.past[this.props.x] === true) {
      let change = 0;

      for (let i=0; i < this.props.answer.length; i++) {
        if (this.props.answer[i].toLowerCase() === this.props.value.toLowerCase()) {
          //console.log(this.props.answer[i] + " ## " + this.props.value);
          change = 1;
          break;
        } else {
          // checking for similar teams
          if (this.props.teamIndex !== -1 && this.props.y === 0) {
            // getting the current team
            let thisTeam = this.props.answer[i];
            let similarFound = false;
            for (let x=0; x < this.props.similar[0].length; x++) {
              if (this.props.similar[0][x][0] === this.props.answer[0]) {
                for (let y=0; y < this.props.similar[0][x][1].length; y++) {
                  //console.log(this.props.guess[0] + " ## " + this.props.similar[0][x][1][y]);
                  if (this.props.guess[0] === this.props.similar[0][x][1][y]) {
                    change = 2;
                    similarFound = true;
                    break;
                  }
                }
              }
              if (similarFound === true) {
                break;
              } else {
                change = 3;
              }
            }
          } else {
            // checking if similar position
            if (this.props.y === 1 && this.state.value !== ``) {
              let similarPosition = false;
              for (let x=0; x < this.props.similar[1].length; x++) {
                if (this.props.similar[1][x][0] === this.props.answer[1]) {
                  for (let y=0; y < this.props.similar[1][x][1].length; y++) {
                    if (this.props.guess[1] === this.props.similar[1][x][1][y]) {
                      change = 2;
                      similarPosition = true;
                      break;
                    }
                  }
                }
              }
              if (similarPosition === true) {
                break;
              } else {
                change = 3;
              }
            } else {

              // checking if similar height
              if (this.props.y === 2 && this.state.value !== ``) {
                let similarHeight = false;
                for (let x=0; x < this.props.similar[2].length; x++) {
                  if (this.props.similar[2][x][0] === this.props.answer[2]) {
                    for (let y=0; y < this.props.similar[2][x][1].length; y++) {
                      if (this.props.guess[2] === this.props.similar[2][x][1][y]) {
                        change = 2;
                        similarHeight = true;
                        break;
                      }
                    }
                  }
                }
                if (similarHeight === true) {
                  break;
                } else {
                  change = 3;
                }
              } else {
                // checking if similar country
                if (this.props.y === 3 && this.state.value !== ``) {
                  let similarCountry = false;
                  for (let x=0; x < this.props.similar[3].length; x++) {
                    //console.log(this.props.similar[3][x][0] + " ## " + this.props.continent);
                    if (this.props.similar[3][x][0] === this.props.continent) {
                      for (let y=0; y < this.props.similar[3][x][1].length; y++) {
                        if (this.props.similar[3][x][1][y] === this.props.guess[3] && this.props.guess[3] !== this.props.answer[3]) {
                          change = 2;
                          similarCountry = true;
                          break;
                        }
                      }
                    }
                  }

                  if (similarCountry === true) {
                    break;
                  } else {
                    change = 3;
                  }
                } else {
                  // checking if similar age
                  if (this.props.y === 4 && this.state.value !== ``) {
                    let similarAge = false;
                    let valueInt = parseInt(this.props.guess[4]);
                    let answerInt = parseInt(this.props.answer[4]);
                    let below = parseInt(answerInt - 1);
                    let above = parseInt(answerInt + 1);
                    if (valueInt !== answerInt) {
                      if (similarAge === false) {
                        if (valueInt >= below && valueInt <= above) {
                          change = 2;
                          similarAge = true;
                        }
                      }
                      if (similarAge === true) {
                        break;
                      } else {
                        change = 3;
                      }
                    }
                  } else {
                    change = 3;
                  }
                }
              }
            
            }
          }
        }
      }

      if (change !== 0) {
        if (this.state.myClass !== "animate__animated animate__flipInX") {
          this.setState({ myClass: "animate__animated animate__flipInX" });
        }
      }

      // correct answer
      if (change === 1) {
        if (this.state.color !== '#538D4E') {
          this.setState({color: '#538D4E'});
          this.setState({colorBG: '#538D4E'});
        }
      }
      // is similar
      if (change === 2) {
        if (this.state.color !== '#7A6B29') {
          this.setState({color: '#7A6B29'});
          this.setState({colorBG: '#7A6B29'});
        }
      }
      // wrong
      if (change === 3) {
        if (this.state.color !== 'gray') {
          this.setState({color: 'gray'});
          this.setState({colorBG: 'gray'});
        }
      }
    }

    /*
    if (this.props.x === 0 && this.props.y === 0) {
      if (this.props.value === "Seattle Seawolves") {
        console.log("hello");
      }
    }*/
    /*
    if (this.props.past[this.props.x][this.props.y] !== '') {
      console.log(this.props.past[this.props.x][this.props.y]);
      // comparing guess to answer
      if (this.props.past[this.props.x][this.props.y] === this.props.answer[this.props.y]) {
        this.setState({ color: "green"});
      }
    }*/
  }

  // Code to display image for team
  /*
  { this.state.image ?
    <img src="https://www.chicagohounds.com/wp-content/uploads/sites/18/2022/12/Hounds-Logo.png" alt="" style={{maxWidth: "80px", maxHeight: "80px"}} /> :
    <p>{this.props.value}</p> 
    }
*/

  render() {
    return (
      <div className={this.state.myClass}>
        <div className="grid-item" style={{borderColor: this.state.color,
                borderStyle: "solid",
                backgroundColor: this.state.colorBG,
                border: "2px 2px", margin: "1px"}}>
          <div style={{ float: "left", whiteSpace: "normal", overflowWrap: "break-word", overflow: "hidden", display: "flex", justifyContent: "center", alignContent: "center", flexDirection: "column", width: "100%", height: "100%"}}>
            <div
              style={{
                color: "white", display: "flex",
                justifyContent: "center", alignContent: "center", flexDirection: "column", textAlign: "center",
                marginLeft: "auto", marginRight: "auto",
              }}
            >
              { this.props.teamIndex !== -1 && this.props.y === 0 ?
              <div style={{position: "relative", width: "100%", height: "100%", display: "flex", textAlign: "center", justifyContent: "center", alignContent: "center", flexDirection: "column", marginLeft: "auto", marginRight: "auto", verticalAlign: "middle" }}>
                <HideUntilLoaded imageToLoad={this.props.teamImages[this.props.teamIndex]} Spinner={()=><span className="loader" style={{ justifyContent: "center", alignContent: "center", flexDirection: "column", marginLeft: "auto", marginRight: "auto" }}></span>}>
                  <img src={this.props.teamImages[this.props.teamIndex]} alt="" style={{ maxWidth: "70%", maxHeight: "70%", justifyContent: "center", alignContent: "center", flexDirection: "column", marginLeft: "auto", marginRight: "auto"}} /> 
                </HideUntilLoaded>
              </div>
              :
              <span className="boxText" style={{ margin: "5px", alignSelf: "center", justifyContent: "center", verticalAlign: "middle", justifyContent: "center", alignContent: "center", flexDirection: "column" }}><b>{this.props.value}</b></span>  
              }
            </div>
          </div>
        </div>
      </div>
    );
  }
}
