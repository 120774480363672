import React from 'react';

import tiles from '../../Static/colourtiles.PNG';

export default class InfoMLR extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return(
            <div style={{ color: "white", marginTop: "25px"}}>
                <h1 style={{textAlign: "center"}}>How to play?</h1>
                <p style={{textAlign: "center"}}>
                    <div style={{padding: '10px'}}>
                        This is a game similar to Wordle for <a target="_blank" href="https://en.wikipedia.org/wiki/Major_League_Rugby">Major League Rugby.</a><br/>
                        The current version of the game is for the 2023 season.<br/>
                        To start off, you have to guess the hints for the player in 5 attempts.<br/>
                        Once you have either got all the correct hints or used all your attempts, you can attempt to guess the player name.<br/>
                        The number of guesses you have for the player name depends on how many attempts you used in the hints part of the game.<br/>
                        In each guess for the hints there are five different categories:
                        <li style={{listStylePosition: "inside"}}>The team the player plays for</li>
                        <li style={{listStylePosition: "inside"}}>The position of the player</li>
                        <li style={{listStylePosition: "inside"}}>The height of the player</li>
                        <li style={{listStylePosition: "inside"}}>The nationality of the player</li>
                        <li style={{listStylePosition: "inside"}}>The age of the player</li>
                        To change which category is selected, click the arrows at the bottom.<br/>
                        After you have submitted a guess for the hints, the colour of the tiles will change to show how close your guess was to the player's info.<br/>
                        For example:<br/>
                    </div>
                    <img src={tiles} alt="" className='img-fluid' style={{maxHeight: "100px", minHeight: "75px"}}/>
                    <br/>
                    <div style={{padding: '10px'}}>
                    The first column of tiles here shows you have got a similar answer as the tile is yellow.<br/>
                    The second column of tiles here shows you have got the answer correct as the tile is green.<br/>
                    The third column of tiles here shows you haven't got a similar or correct answer as the tile is gray.<br/>
                    The fourth and fifth column also shows you have not got a correct answer.<br/>
                    <br/><br/>
                    For this gamemode, the similarities are different for each category. The similarities for each category is explained below:
                    <p><b>Teams</b>
                        <br/>
                        For a team to be similar, it has to be in the same conference as the answer.<br/>
                        For example, if the answer for the team was San Diego Legion, a similar team would be Seattle Seawolves.
                    </p><br/>
                    <p>
                        <b>Position</b><br/>
                        For a position to be similar, it has to be a position that is similar in role/area.<br/>
                        For example, if the answer for the position was prop, a similar position would be hooker as they are both within the front row.
                        <br/>
                        Since finding similar positions might be confusing at first, here are the positions that are similar to each other:
                        <br/>
                        {
                            this.props.similar[1].map((position, key)=>(
                                <li style={{listStyleType: "square", listStylePosition: "inside"}}>
                                    {position[0]}: {position[1].join(", ")}
                                </li>
                            ))
                        } 
                    </p><br/>
                    <p>
                        <b>Height</b><br/>
                        For a height to be similar, it has to be within one inch of the answer height.<br/>
                        For example, if the answer for the position was 6'2", a similar height would be either 6'1" or 6'3".
                    </p><br/>
                    <p>
                        <b>Nationality</b><br/>
                        For a nationality to be similar, the country selected has to be within the same continent as the answer for the nationality.<br/>
                        For example, if the answer for the nationality was England, a similar nationality would be France.
                    </p><br/>
                    <p>
                        <b>Age</b><br/>
                        For the age to be similar, the age that has been selected has to be within one year of the answer for the age.<br/>
                        For example, if the answer for the age was 25, a similar age would be either 24 or 26.
                    </p>
                    <br/>
                    If you are confused while playing and forget anything, you can refer back to here by clicking the <i class="bi bi-question-circle-fill"></i> icon.
                    </div>
                </p><br/>
            </div>
        );
    }
}