import React from 'react';

import Navbar from '../Components/Navbar';

export default class Home extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return(
            <div>
                <Navbar />
                <div style={{color: "white", marginTop: "50px"}}>
                    <div style={{textAlign: "center"}}>
                        <h1 style={{fontSize: "7.5vw"}}>Player Hunch</h1>
                        <br/>
                        <h6>We create games for sports fans.</h6>
                    </div>
                    <div style={{display: "flex", flexFlow: "row wrap", justifyContent: "center", marginLeft: "auto", marginRight: "auto", marginTop: "100px", height: "400px"}}>
                        <div style={{height: "300px", marginBottom: "10px"}}>
                            <div className='contentBox'>
                                <h3>Guess the Player</h3>
                                <h6>MLR Edition</h6>
                                <a href="/games/mlr"><input type="submit" value="Play!" className='btn btn-success' style={{margin: "10px"}} /></a>
                                <p>A Wordle style game to guess the randomly picked player for the day for Major League Rugby.</p>
                            </div>
                        </div>
                        <div className='contentBox'>
                            <h3>Guess the Player</h3>
                            <h6>Premier League Edition</h6>
                            <a href="/games/premier-league"><input type="submit" value="Play!" className='btn btn-success' style={{margin: "10px"}} /></a>
                            <p>A Wordle style game to guess the randomly picked player for the day for the Premier League.</p>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}