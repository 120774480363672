import ReactDOM from "react-dom/client";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import MLR from './Pages/Pages/MLR';
import PL from './Pages/Pages/PL';
import Home from './Pages/Pages/Home';
import About from "./Pages/Pages/About";
import TermsAndConditions from "./Pages/Pages/TermsAndConditions";

import './index.css';
import { useEffect } from 'react';
import PrivacyPolicy from "./Pages/Pages/PrivacyPolicy";

function App() {
  useEffect(()=> {
    document.body.style = 'background: #121213;';
  }, []);

  return (
    <Router>
      <Routes>
        <Route exact path="/" element={<Home/>}/>
        <Route exact path="/home" element={<Home/>}/>
        <Route exact path="/about" element={<About/>}/>
        <Route exact path="/games/mlr" element={<MLR/>}/>
        <Route exact path="/games/premier-league" element={<PL/>}/>
        <Route exact path="/terms-and-conditions" element={<TermsAndConditions/>}/>
        <Route exact path="/privacy-policy" element={<PrivacyPolicy/>}/>
        <Route path="*" element={<Home/>}/>
      </Routes>
    </Router>
  );
}

export default App;
