import React from 'react';

export default class Navbar extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return(
            <div>
                <nav className="navbar navbar-expand-lg navbar-dark" style={{backgroundColor: "transparent", color: "white", border: "1px", borderColor: "#3A3A3C", borderBottomStyle: "solid",
  borderTopStyle: "none",
  borderRightStyle: "none", borderLeftStyle: "none"}}>
                    <a className="navbar-brand" href="/home">Player Hunch</a>
                    <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>

                    <div className="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul className="navbar-nav mr-auto">
                            <li className="nav-item active">
                                <a className="nav-link" href="/home">Home<span className="sr-only"></span></a>
                            </li>
                            <li class="nav-item dropdown navbar-dark active">
                                <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                Games
                                </a>
                                <div class="dropdown-menu" aria-labelledby="navbarDropdown">
                                    <a class="dropdown-item" href="/games/mlr">Guess the Player - MLR Edition</a>
                                    <a class="dropdown-item" href="/games/premier-league">Guess the Player - Premier League Edition</a>
                                </div>
                            </li>
                            <li className="nav-item active">
                                <a className="nav-link" href="/about">About<span className="sr-only"></span></a>
                            </li>
                        </ul>
                    </div>
                </nav>
            </div>
        );
    }
}